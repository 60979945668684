<template>
    <main>
        <page-header title="Reporte Relacion de Facturas"></page-header>
        <div class="container-fluid mt-n10">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-3 form-group">
                            <label class="small mb-0">Fecha Inicio</label>
                            <input type="date" class="form-control form-control-sm" v-model="fecha_inicio">
                        </div>
                        <div class="col-3 form-group">
                            <label class="small mb-0">Fecha Final</label>
                            <input type="date" class="form-control form-control-sm" v-model="fecha_final">
                        </div>
                        <div class="form-group col-2 d-flex justify-content-end align-items-end">
                            <button class="btn btn-primary btn-sm shadow-sm btn-block"  @click="reporte()">Reporte</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import PageHeader from "../../../components/layouts/content/page-header";
import FacturaService from "../../../services/facturaService";
import Swal from "sweetalert2/src/sweetalert2";
import {downloadFile} from "../../../utils/utils";

export default {
    components: {PageHeader},
    data () {
        return {
            fecha_inicio :  '',
            fecha_final : '',
        }
    },
    methods : {
        async reporte(){
            try{

                const params = {
                    fecha_inicio : this.fecha_inicio,
                    fecha_final : this.fecha_final
                };

                this.LoaderSpinnerShow();

                let response = await FacturaService.report('xls', params);

                this.LoaderSpinnerHide();

                Swal.fire('Reporte generado con exito', '', 'success');

                downloadFile(
                    response.data,
                    response.headers['content-type'],
                    `InvoiceReport_${this.fecha_inicio}_to_${this.fecha_final}`
                );

            }catch (e) {
                this.LoaderSpinnerHide();
                console.error(e);
                Swal.fire('Ocurrio un error al generar el reporte','','error');
            }
        }
    }

}
</script>

<style scoped>

</style>
